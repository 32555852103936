import Public from "./Public";
import Protected from "./Protected";
import AppLayout from "../layout/Layout";
import LazyComponent from "./LazyComponent";
import Tier1Member from "../screens/tier1/tier1Member";
import Tier2Member from "../screens/tier2/tier2Member";
import Tier3Member from "../screens/tier3/tier3Member";
import tier4Member from "../screens/tier4/tier4Member";
import { loader as bugsLoader } from "../screens/bugs/bugs";
import { loader as Tier1Loader } from "../screens/tier1/tier1";
import { loader as Tier2Loader } from "../screens/tier2/tier2";
import { loader as Tier3Loader } from "../screens/tier3/tier3";
import { loader as editTier1Loader } from "../screens/tier1/tier1Edit";
import { loader as editTier2Loader } from "../screens/tier2/tier2Edit";
import { loader as approvalLoader } from "../screens/approval/approval";
import { loader as billingLoader } from "../screens/billing/billingInfo";
import { loader as dashboardLoader } from "../screens/dashboard/Dashboard";
import { loader as businessCardLoader } from "../screens/business/business";
import { loader as editBusinessLoader } from "../screens/tier1/editBusiness";
import { loader as suggestionLoader } from "../screens/suggestion/suggestion";
import { loader as categoriesLoader } from "../screens/categories/categories";
import { loader as editBusinessLoaderTier2 } from "../screens/tier2/tier2BusinessCard";
import { loader as tier1CompanyLoader } from "../screens/tier1/Company";
import { loader as tier2CompanyLoader } from "../screens/tier2/Company";
import { loader as FranchiseDocumentLoader } from "../screens/franchiseDocs/FranchiseDocs";
import { loader as StateDocs } from "../screens/stateDocs/StateDocs";
import { loader as CustomDocs } from "../screens/customDocs/CustomDocs";
import { loader as NationalCompany } from "../screens/tier2/nationalCompany";
import { loader as RegionalCompany } from "../screens/tier2/regionalCompany";

export const SiteMap = {
  home: {
    title: "home",
    path: "/",
    element: (
      <Public>
        <LazyComponent path="login/login" />
      </Public>
    ),
    description: "home-Page",
  },
  dashboard: {
    title: "dashboard",
    path: "/dashboard",
    element: (
      <Protected>
        <AppLayout>
          <LazyComponent path="dashboard/Dashboard" />
        </AppLayout>
      </Protected>
    ),
    loader: dashboardLoader,
  },

  subAdmin: {
    title: "subAdmin",
    path: "/subAdmin",
    element: (
      <Protected>
        <AppLayout>
          <LazyComponent path="subAdmin/subAdmin" />
        </AppLayout>
      </Protected>
    ),
  },
  tier1: {
    title: "tier1",
    path: "/tier1",
    element: (
      <Protected>
        <AppLayout showBackButton={false} showSearchBox={true}>
          <LazyComponent path="tier1/tier1" />
        </AppLayout>
      </Protected>
    ),
    loader: Tier1Loader,
    description: "tier1",
  },

  tier1Member: {
    title: "tier1Member",
    path: "/tier1/info/detail1/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier1/tier1Member" />
        </AppLayout>
      </Protected>
    ),
    loader: Tier1Member,
    description: "tier1Member",
  },
  Tier1DetailsT1: {
    title: "tier1DetailsT1",
    path: "/tier1/info/detailTier1/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier1/tier1DetailsT1" />
        </AppLayout>
      </Protected>
    ),
    description: "tier1DetailsT1",
  },
  Tier1DetailsT2: {
    title: "tier1DetailsT2",
    path: "/tier1/info/detailTier2/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier1/tier1DetailsT2" />
        </AppLayout>
      </Protected>
    ),
    description: "tier1DetailsT2",
  },
  Tier1DetailsT3: {
    title: "tier1DetailsT3",
    path: "/tier1/info/detailTier3/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier1/tier1DetailsT3" />
        </AppLayout>
      </Protected>
    ),
    description: "tier1DetailsT3",
  },
  Tier1DetailsT4: {
    title: "tier1DetailsT4",
    path: "/tier1/info/detailTier4/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier1/tier1DetailsT4" />
        </AppLayout>
      </Protected>
    ),
    description: "tier1DetailsT4",
  },
  tier1Company: {
    title: "tier1Company",
    path: "/tier1/company",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={true}>
          <LazyComponent path="tier1/Company" />
        </AppLayout>
      </Protected>
    ),
    loader: tier1CompanyLoader,
    description: "tier1Company",
  },
  tier1CompanyAddForm: {
    title: "tier1CompanyAddForm",
    path: "/tier1/company/companyForm",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="forms/tier1CompanyForm" />
        </AppLayout>
      </Protected>
    ),
    description: "tier1CompanyAddForm",
  },
  tier1CompanyEditForm: {
    title: "tier1CompanyEditForm",
    path: "/tier1/company/companyForm/:companyId",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="forms/tier1CompanyForm" />
        </AppLayout>
      </Protected>
    ),
    description: "tier1CompanyEditForm",
  },
  tier1CompanyMembers: {
    title: "tier1CompanyDetails",
    path: "/tier1/company/info/:companyId",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier1/tier1CompanyDetails" />
        </AppLayout>
      </Protected>
    ),
    description: "tier1CompanyDetails",
  },
  tier1Edit: {
    title: "tier1Edit",
    path: "/tier1/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier1/tier1Edit" />
        </AppLayout>
      </Protected>
    ),
    loader: editTier1Loader,
    description: "tier1",
  },
  tier1CompanyDocument: {
    title: "tier1CompanyDocuments",
    path: "/tier1/company/documents/:companyId",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier1/tier1CompanyDocuments" />
        </AppLayout>
      </Protected>
    ),
    description: "tier1CompanyDocuments",
  },
  tier2: {
    title: "tier2",
    path: "/tier2",
    element: (
      <Protected>
        <AppLayout showBackButton={false} showSearchBox={true}>
          <LazyComponent path="tier2/tier2" />
        </AppLayout>
      </Protected>
    ),
    description: "tier2",
    loader: Tier2Loader,
  },
  tier2Member: {
    title: "tier2Member",
    path: "/tier2/info/detail2/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier2/tier2Member" />
        </AppLayout>
      </Protected>
    ),
    loader: Tier2Member,
    description: "tier2Member",
  },
  tier2CompanyMembers: {
    title: "tier2CompanyDetails",
    path: "/tier2/company/info/:companyId",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier2/tier2CompanyDetails" />
        </AppLayout>
      </Protected>
    ),
    description: "tier2CompanyDetails",
  },
  tier2NationalCompanyMember: {
    title: "tier2NationalCompanyMember",
    path: "/tier2/national-company/info/:nationalId",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier2/tier2NationalCompanyDetail" />
        </AppLayout>
      </Protected>
    )
  },
  tier2NationalCompany: {
    title: 'tier2NationalCompany',
    path: '/tier2/national-company',
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier2/nationalCompany" />
        </AppLayout>
      </Protected>
    ),
    loader: NationalCompany,
    description: "tier2NationalCompany"
  },
  tier2NationalCompanyForm: {
    title: "tier2NationalCompanyForm",
    path: '/tier2/national-company/nationalCompanyForm',
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="forms/nationalCompanyForm" />
        </AppLayout>
      </Protected>
    )
  },
  tier2NationalCompanyEditForm: {
    title: "tier2NationalCompanyEditForm",
    path: "/tier2/national-company/nationalCompanyForm/:nationalId",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="forms/nationalCompanyForm" />
        </AppLayout>
      </Protected>
    )
  },
  tier2RegionalCompanyMember: {
    title: "tier2RegionalCompanyMember",
    path: "/tier2/regional-company/info/:regionalId",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier2/tier2RegionalCompanyDetail" />
        </AppLayout>
      </Protected>
    )
  },
  tier2RegionalCompany: {
    title: 'tier2RegionalCompany',
    path: '/tier2/regional-company',
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier2/regionalCompany" />
        </AppLayout>
      </Protected>
    ),
    loader: RegionalCompany,
    description: "tier2RegionalCompany"
  },
  tier2RegionalCompanyForm: {
    title: "tier2RegionalCompanyForm",
    path: "/tier2/regional-company/regionalCompanyForm",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="forms/regionalCompanyForm" />
        </AppLayout>
      </Protected>
    )
  },
  tier2RegionalCompanyEditForm: {
    title: "tier2RegionalCompanyEditForm",
    path: "/tier2/regional-company/regionalCompanyForm/:regionalId",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="forms/regionalCompanyForm" />
        </AppLayout>
      </Protected>
    ),
    description: "tier2RegionalCompanyEditForm"
  },
  tier2Company: {
    title: "tier2Company",
    path: "/tier2/company",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={true}>
          <LazyComponent path="tier2/Company" />
        </AppLayout>
      </Protected>
    ),
    loader: tier2CompanyLoader,
    description: "tier2Company",
  },
  tier2CompanyAddForm: {
    title: "tier2CompanyAddForm",
    path: "/tier2/company/companyForm",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="forms/tier2CompanyForm" />
        </AppLayout>
      </Protected>
    ),
    description: "tier2CompanyAddForm",
  },
  tier2CompanyEditForm: {
    title: "tier2CompanyEditForm",
    path: "/tier2/company/companyForm/:companyId",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="forms/tier2CompanyForm" />
        </AppLayout>
      </Protected>
    ),
    description: "tier2CompanyEditForm",
  },
  tier2Edit: {
    title: "tier2Edit",
    path: "/tier2/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier2/tier2Edit" />
        </AppLayout>
      </Protected>
    ),
    loader: editTier2Loader,
    description: "tier2-details",
  },

  tier3: {
    title: "tier3",
    path: "/tier3",
    element: (
      <Protected>
        <AppLayout showBackButton={false} showSearchBox={true}>
          <LazyComponent path="tier3/tier3" />
        </AppLayout>
      </Protected>
    ),
    loader: Tier3Loader,
    description: "Tier-3-page",
  },
  tier3Member: {
    title: "tier3Member",
    path: "/tier3/info/detail3/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier3/tier3Member" />
        </AppLayout>
      </Protected>
    ),
    loader: Tier3Member,
    description: "tier3Member",
  },
  Tier2DetailsT3: {
    title: "tier2DetailsT3",
    path: "/tier2/info/detailTier3/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier2/tier2DetailsT3" />
        </AppLayout>
      </Protected>
    ),
    description: "tier2DetailsT3",
  },
  Tier2DetailsT4: {
    title: "Tier2DetailsT4",
    path: "/tier2/info/detailTier4/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier2/tier2DetailsT4" />
        </AppLayout>
      </Protected>
    ),
    description: "Tier2DetailsT4",
  },
  tier3Edit: {
    title: "tier3Edit",
    path: "/tier3/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier3/tier3Edit" />
        </AppLayout>
      </Protected>
    ),
    description: "Details",
  },
  tier4: {
    title: "tier4",
    path: "/tier4",
    element: (
      <Protected>
        <AppLayout showBackButton={false} showSearchBox={true}>
          <LazyComponent path="tier4/tier4" />
        </AppLayout>
      </Protected>
    ),
    description: "Tier-4-page",
  },
  tier4Member: {
    title: "tier4Member",
    path: "/tier4/info/detail4/:id",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="tier4/tier4Member" />
        </AppLayout>
      </Protected>
    ),
    loader: tier4Member,
    description: "tier4Member",
  },

  webForm: {
    title: "webForm",
    path: "/webForm/:id",
    element: <LazyComponent path="webForm/webForm" />,
    description: "web-form-details",
  },
  OtpVerify: {
    title: "otpVerify",
    path: "/otpVerify",
    element: <LazyComponent path="webForm/otpVerify" />,
    description: "otpVerify-details",
  },
  Acceptence: {
    title: "acceptence",
    path: "/acceptence/:type/:id",
    element: <LazyComponent path="webForm/acceptence" />,
    description: "acceptence-details",
  },
  firstForm: {
    title: "firstForm",
    path: "/firstForm/:id",
    element: <LazyComponent path="webForm/firstForm" />,
    description: "first-form-details",
  },
  approval: {
    title: "approval",
    path: "/approval/:id",
    element: <LazyComponent path="approval/approval" />,
    loader: approvalLoader,
    description: "web-form details",
  },
  accepted: {
    title: "accepted",
    path: "/accepted/:id",
    element: <LazyComponent path="webForm/accepted" />,
    description: "web-form details",
  },
  termOfService: {
    title: "termOfService",
    path: "/termOfService/:id",
    element: <LazyComponent path="webForm/termOfService" />,
    description: "termOfService details",
  },
  privacyPolicy: {
    title: "privacyPolicy",
    path: "/privacyPolicy/:id",
    element: <LazyComponent path="webForm/privacyPolicy" />,
    description: "privacyPolicy details",
  },
  declined: {
    title: "declined",
    path: "/declined/:id",
    element: <LazyComponent path="webForm/declined" />,
    loader: approvalLoader,
    description: "web-form details",
  },
  welcome: {
    title: "welcome",
    path: "/welcome/:id",
    element: <LazyComponent path="webForm/welcome" />,
    description: "web-form details",
  },
  refferForm: {
    title: "reffer-form",
    path: "/reffer-form/:id?",
    element: <LazyComponent path="forms/refferMembershipForm" />,
    description: "Reffer-form details",
  },
  tierForm: {
    title: "tier-form",
    path: "/tier-form",
    element: (
      <Protected>
        <LazyComponent path="forms/tierForm" />
      </Protected>
    ),
    description: "Tier-form details",
  },
  tierAdminForm: {
    title: "tier-AdminForm",
    path: "/tier-adminForm",
    element: (
      <Protected>
        <LazyComponent path="forms/tierAdminForm" />
      </Protected>
    ),
    description: "Tier-adminForm details",
  },
  tierFormSecond: {
    title: "tier-form-Second",
    path: "/tier-form_second",
    element: (
      <Protected>
        <LazyComponent path="forms/tierForm2" />
      </Protected>
    ),
    description: "Tier-form-second details",
  },
  interviewerForm: {
    title: "interviewer-form",
    path: "/interviewer-form/:id",
    element: <LazyComponent path="forms/interviewerMembershipForm" />,
    description: "Interviewer-form details",
  },
  editBusinessForm: {
    title: "Edit business form tier 1",
    path: "/edit-business-form/tier-1/:id",
    element: <LazyComponent path="tier1/editBusiness" />,
    loader: editBusinessLoader,
    description: "Edit business form tier 1",
  },
  editBusinessFormTier2: {
    title: "Edit business form tier 2",
    path: "/edit-business-form/tier-2/:id",
    element: <LazyComponent path="tier2/tier2BusinessCard" />,
    loader: editBusinessLoaderTier2,
    description: "Edit business form tier 2",
  },
  BusinessCardForm: {
    title: "Business-Details",
    path: "/business-details",
    element: <LazyComponent path="tier1/businessDetails" />,
    description: "Business-Details",
  },
  BusinessCard: {
    title: "Business-Card-Details",
    path: "/business-card-details/:id",
    element: <LazyComponent path="business/business" />,
    description: "Business-card-Details",
    loader: businessCardLoader,
  },
  ThankYou: {
    title: "Thank-You",
    path: "/success",
    element: <LazyComponent path="forms/thankYou" />,
    description: "Business-Details",
  },
  ThankYouPrivate: {
    title: "ThankYouPrivate",
    path: "/successForm",
    element: <LazyComponent path="forms/thankYouPrivate" />,
    description: "Business-Details",
  },
  VCardEdit: {
    title: "VCardEdit",
    path: "/vcard/edit/:id",
    element: <LazyComponent path="enBizCard/vCardEdit" />,
    description: "VCardEdit-Details",
  },
  VCard: {
    title: "VCard",
    path: "/vcard/:id",
    element: <LazyComponent path="enBizCard/vCard" />,
    description: "vCard-Details",
  },
  Directory: {
    title: "Directory",
    path: "/directory",
    element: (
      <Protected>
        <AppLayout>
          <LazyComponent path="directory/directory" />
        </AppLayout>
      </Protected>
    ),
    description: "Directory",
  },
  Categories: {
    title: "categories",
    path: "/categories",
    element: (
      <Protected>
        <AppLayout>
          <LazyComponent path="categories/categories" />
        </AppLayout>
      </Protected>
    ),
    loader: categoriesLoader,
    description: "categories",
  },
  Suggestions: {
    title: "Suggestions",
    path: "/suggestions",
    element: (
      <Protected>
        <AppLayout>
          <LazyComponent path="suggestion/suggestion" />
        </AppLayout>
      </Protected>
    ),
    loader: suggestionLoader,
    description: "suggestion",
  },
  Bugs: {
    title: "Bugs",
    path: "/bugs",
    element: (
      <Protected>
        <AppLayout>
          <LazyComponent path="bugs/bugs" />
        </AppLayout>
      </Protected>
    ),
    loader: bugsLoader,
    description: "bugs",
  },
  BillingInfo: {
    title: "Billing Info",
    path: "/billing-info",
    element: (
      <Protected>
        <AppLayout>
          <LazyComponent path="billing/billingInfo" />
        </AppLayout>
      </Protected>
    ),
    loader: billingLoader,
    description: "Billing-info",
  },
  stateDocs: {
    title: "state-docs",
    path: "/state-docs/stateDocsForm",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="forms/stateDocsForm" />
        </AppLayout>
      </Protected>
    ),
    loader: StateDocs,
    description: 'state-docs',
  },
  StateDocs: {
    title: "State Docs",
    path: "/state-docs",
    element: (
      <Protected>
        <AppLayout showSearchBox={true}>
          <LazyComponent path="stateDocs/StateDocs" />
        </AppLayout>
      </Protected>
    ),
    loader: StateDocs,
    // description: "state docs",
  },
  stateDocsEdit: {
    title: 'stateDocsEditForm',
    path: "/state-docs/stateDocumentForm/:documentId",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="forms/stateDocsForm" />
        </AppLayout>
      </Protected>
    ),
    description: 'stateDocsEditForm'
  },
  customDocument: {
    title: 'custom document',
    path: '/custom-docs/customDocsForm',
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="forms/customDocsForm" />
        </AppLayout>
      </Protected>
    ),
    description: 'custom-document',
  },
  CustomDocs: {
    title: "Custom Document",
    path: "/custom-docs",
    element: (
      <Protected>
        <AppLayout showSearchBox={true}>
          <LazyComponent path="customDocs/CustomDocs" />
        </AppLayout>
      </Protected>
    ),
    loader: CustomDocs,
  },
  customDocumentEdit: {
    title: 'customDocumentEdit',
    path: "/custom-document/customDocumentForm/:documentId",
    element: (
      <Protected>
        <AppLayout showBackButton={true} showSearchBox={false}>
          <LazyComponent path="forms/customDocumentForm" />
        </AppLayout>
      </Protected>
    ),
    description: 'customDocumentEdit',
  },
  FranchiseDocs: {
    title: "Franchise Docs",
    path: "/franchise-docs",
    element: (
      <Protected>
        <AppLayout showBackButton={false} showSearchBox={true}>
          <LazyComponent path="franchiseDocs/FranchiseDocs" />
        </AppLayout>
      </Protected>
    ),
    loader: FranchiseDocumentLoader,
  },
  franchiseAddForm: {
    title: "Franchise Docs",
    path: "/franchise-docs/franchisedoc-form",
    element: (
      <Protected>
        <AppLayout showBackButton={true}>
          <LazyComponent path="forms/franchiseDocsForm" />
        </AppLayout>
      </Protected>
    ),
    // loader: FranchiseDocumentLoader,
    description: "Franchise Docs Details",
  },
};
