import { Button, message, Popconfirm, Table, Typography } from "antd";
import axios from "axios";
import React, { useEffect } from "react";
import { END_POINT, PAGE_LIMIT } from "../../assets/constants/global";
import dayjs from "dayjs";
import {
  NavLink,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

function FranchiseDocScreen() {
  const data = useLoaderData();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const confirmFranchiseDocumentDelete = async (id) => {
    if (!id) {
      message?.error("Failed to delete");
      return
    }
    try {
      const response = await axios.delete(`${END_POINT}/v1/document/delete/${id}`)
      if (response.data.success) {
        navigate("/franchise-docs");
        message?.success(response.data.message)
      }
    } catch (error) {
      console.error("error:", error);
    }
  }
  const cancel = () => { };

  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => dayjs(text).format("DD/MM/YYYY HH:MM:ss"),
    },
    {
      title: "Document ID",
      dataIndex: "documentID",
      key: "documentID",
      render: (text) => text || "N/A",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => text || "N/A",
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      render: (text, record) => {
        return (
          <Button onClick={() => {
            if (record?.editDocUrl) {
              window.open(record.editDocUrl, "_blank");
            }
          }}>View Document</Button>
        )
      }
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Popconfirm
            title="Delete the document"
            description="Are you sure to delete this document?"
            onConfirm={() => confirmFranchiseDocumentDelete(record?._id)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button>Remove</Button>
          </Popconfirm>
        );
      },
    },
  ];
  useEffect(() => {
    setSearchParams({ pageNumber: 1 });
  }, [])

  const handleTableChange = async (page) => {
    const pageNumber = page?.current;
    setSearchParams({ pageNumber: pageNumber });
  };

  return (
    <div className="main_container_div">
      <div className="inner_div_2 main-header">
        <Typography className="heading_text">
          <span className="primary-heading" style={{ color: "black" }}>
            Franchise Documents
          </span>
        </Typography>
        <NavLink to={"/franchise-docs/franchisedoc-form"}>
          <Button className="text-hide">Add+</Button>
        </NavLink>
      </div>
      <div className="main_inner_div">
        <Table
          columns={columns}
          dataSource={data?.data}
          onChange={(page) => handleTableChange(page)}
          pagination={{
            pageSize: PAGE_LIMIT,
            total: data?.length,
          }}
          scroll={{ x: 700 }}
        />
      </div>
    </div>
  );
}

export default FranchiseDocScreen;
