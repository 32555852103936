import React from "react";
import StateDocsScreen from "../../components/stateDocs/StateDocsScreen";
import { END_POINT } from "../../assets/constants/global";
import axios from "axios";
import { defer } from "react-router-dom";

export async function loader({ request }) {
  try {
    const url = new URL(request.url);
    const pageNumber = url.searchParams.get("pageNumber") || "";
    const page_nmuber = pageNumber ? pageNumber : '1'
    const API_URL = `${END_POINT}/v1/document/list?page=${page_nmuber}&type=State Docs`;

    async function fetchData() {
      try {
        const response = await axios.get(API_URL);
        return response.data;
      } catch (error) {
        return [];
      }
    }
    const data = await fetchData();
    return defer({
      data: data?.data ? data?.data : [],
      length: data?.total || 0,
    });
  } catch (error) {
    throw new Error("Failed to load data");
  }
}

const StateDocs = () => {
  return <StateDocsScreen />;
};
export default StateDocs