import { Button, message, Popconfirm, Table, Typography } from "antd";
import React, { useEffect } from "react";
import dayjs from "dayjs";
import { NavLink, useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import { END_POINT, PAGE_LIMIT } from "../../assets/constants/global";
import axios from "axios";

function CustomDocsScreen() {
  const data = useLoaderData();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleTableChange = async (page) => {
    const pageNumber = page?.current;
    setSearchParams({ pageNumber: pageNumber });
  };

  const confirmDelete = async (documentId) => {
    if (!documentId) {
      message?.error("Failed to delete.");
      return;
    }
    try {
      const response = await axios.delete(`${END_POINT}/v1/document/delete/${documentId}`)
      if (response.data.success) {
        navigate("/custom-docs")
        message?.success(response.data.message);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  }

  const cancel = () => { };

  useEffect(() => {
    setSearchParams({ pageNumber: 1 })
  }, [])

  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        return dayjs(text).format("DD/MM/YYYY HH:MM:ss");
      },
    },
    {
      title: 'Document ID',
      dataIndex: 'documentID',
      key: "documentID",
      render: (text) => text || "N/A",
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: "name",
      render: (text) => text || "N/A",
    },
    {
      title: 'Document',
      dataIndex: 'document',
      key: "document",
      render: (text, record) => {
        return (
          <Button onClick={() => {
            if (record?.editDocUrl) {
              window.open(record.editDocUrl, "_blank");
            }
          }}>
            View Document</Button>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: "action",
      render: (text, record) => {
        return (
          <Popconfirm
            title="Delete the document"
            description="Are you sure to delete this document?"
            onConfirm={() => confirmDelete(record?._id)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button>Remove</Button>
          </Popconfirm>
        )
        // return (
        //     <Button onClick={() =>
        //         navigate(`/custom-document/customDocumentForm/${record?._id}`)
        //     }
        //     > Edit</Button>
        // )
      }
    },
  ]

  return (
    <div className="main_container_div">
      <div className="inner_div_2 main-header">
        <Typography className="heading_text">
          <span className="primary-heading" style={{ color: "black" }}>
            Custom Documents
          </span>
        </Typography>
        <NavLink to="/custom-docs/customDocsForm">
          <Button className="text-hide">Add+</Button>
        </NavLink>
      </div>
      <div className="main_inner_div">
        <Table className="text-hide"
          dataSource={data?.data}
          columns={columns}
          onChange={(page) => handleTableChange(page)}
          pagination={{
            pageSize: PAGE_LIMIT,
            total: data?.length,
          }}
          scroll={{ x: 700 }}
        />
      </div>
    </div>
  )
}

export default CustomDocsScreen;