import axios from "axios";
import { END_POINT } from "../../assets/constants/global";
import Tier2RegionalCompany from "../../components/tier2/tier2RegionalCompany";
import { defer } from "react-router-dom";

export async function loader(props) {
  const { request } = props;
  try {
    const url = new URL(request.url);
    const type = "regional";
    const pageNumber = url.searchParams.get("pageNumber") || "";
    const query = url.searchParams.get("query");
    const API_URL = query
      ? `${END_POINT}/v1/companynation/regional?type=${type}&page=${pageNumber}&query=${query}`
      : `${END_POINT}/v1/companynation/regional?type=${type}&page=${pageNumber}`

    async function fetchData() {
      try {
        const response = await axios.get(API_URL)
        return response.data
      } catch (error) {
        return [];
      }
    }

    const data = await fetchData();
    return defer({
      data: data.data ? data.data : [],
      length: data?.total || 0,
    })

  } catch (error) {
    throw new Error("Failed to load data");
  }
}

const RegionalCompany = () => {
  return <Tier2RegionalCompany />
}

export default RegionalCompany;