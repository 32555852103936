import axios from "axios";
import { END_POINT } from "../../assets/constants/global";
import Tier2NationalCompany from "../../components/tier2/tier2NationalCompany";
import { defer } from "react-router-dom";

export async function loader(props) {
  const { request } = props;
  try {
    const url = new URL(request.url);
    const pageNumber = url.searchParams.get("pageNumber") || "";
    const query = url.searchParams.get("query");
    const type = "national"
    const API_URL = query
      ? `${END_POINT}/v1/companynation/national?type=${type}&page=${pageNumber}&query=${query}`
      : `${END_POINT}/v1/companynation/national?type=${type}&page=${pageNumber}`

    async function fetchData() {
      try {
        const response = await axios.get(API_URL)
        return response.data;
      } catch (error) {
        return [];
      }
    }
    const data = await fetchData();
    return defer({
      data: data.data ? data.data : [],
      length: data?.total || 0,
    })
  } catch (error) {
    throw new Error("Failed to load data");
  }
}


const NationalCompany = () => {
  return <Tier2NationalCompany />
}

export default NationalCompany;